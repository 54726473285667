import moment from "moment";
import "moment-duration-format";
import React from "react";
import {FlexboxGrid, Tooltip, Whisper, IconButton} from "rsuite";
import InfoRoundIcon from "@rsuite/icons/InfoRound";
import CopyIcon from "@rsuite/icons/Copy";
import { formatPriceWithCommas } from "../helper/utility";
import "./offer-results-card.less";
import { ReferenceDisplay } from "../../../reference/react-reference-display";

const OptionalText = ({
  text,
  tag: Tag = "p",
  className = undefined,
  style = undefined,
  color,
  format = (x) => x,
}) =>
  !!text && (
    <Tag className={className} style={Object.assign({}, style ?? {}, {backgroundColor: color})}>
      {format(text)}
    </Tag>
  );

const Info = ({ product, title, tooltip }) => {
  const {
    featuresText,
    locationText,
    accommodationText,
    fareText,
    busParkingText,
  } = product;
  const lang = "de";
  const lines = [
    {
      text: title,
      className: "title-tooltip-info",
    },
    {
      text: featuresText?.[lang],
      format: (x) => `Hotelausstattung: ${x}`,
    },
    {
      text: locationText?.[lang],
      format: (x) => `Lage/Anbindung: ${x}`,
    },
    {
      text: accommodationText?.[lang],
      format: (x) => `Zimmer: ${x}`,
    },
    {
      text: fareText?.[lang],
      format: (x) => `Verpflegung: ${x}`,
    },
    {
      text: busParkingText?.[lang],
      format: (x) => `Busparkplatz: ${x}`,
    },
  ];

  for (let tip of tooltip ?? []) {
    lines.push({text: tip});
  }

  return (
    <Whisper
      placement="left"
      speaker={
        <Tooltip>
          {lines.map((line, index) => (
            <OptionalText key={line.text ?? index} {...line} />
          ))}
        </Tooltip>
      }
    >
      <IconButton size="xs" icon={<InfoRoundIcon />} />
    </Whisper>
  );
};
const formatDuration = ({ hours, minutes }) =>
  moment
    .duration({
      hours: hours || 0,
      minutes: minutes || 0,
    })
    .format("hh:mm [h]", { trim: false });

export const OfferResultsCard = ({ item, hideTooltip, showPurchase }) => {
  const {
    title,
    pricePerPerson,
    purchasePricePerPerson,
    city,
    country,
    tags,
    provider,
    product,
    priceLabel,
    contingentLabel,
    availableContingent,
    availableContingentNet,
    comparePrice,
    cancellationPeriod,
    tooltip,
    twinPosition,
  } = item;

  const isAccommodationTab = provider?.includes("tourism-accommodation");
  const location = city ? `${city}, ${country}` : country;

  const rating = product.holidayCheckRating?.averageRecommendationRate;
  const holidayCheck = rating ? Math.round(rating * 10) / 10 : null;

  let showPrice = showPurchase ? purchasePricePerPerson : pricePerPerson;

  const formattedPrice = showPrice ? formatPriceWithCommas(
    showPrice.amount,
    showPrice.currency
  ) : null;

  const comparePriceFormatted = comparePrice
    ? formatPriceWithCommas(comparePrice.amount, comparePrice.currency)
    : null;
  return (
    <FlexboxGrid className="result-card">
      <div className="result-card-row">
        <ReferenceDisplay reference={product}>
          <div className="result-card-title">{title}</div>
        </ReferenceDisplay>
        {isAccommodationTab && (
          <div className="result-card--no-wrap">
            {twinPosition && (
              <span
                style={{
                  margin: "4px",
                }}
              >
                <CopyIcon />
              </span>
            )}
            {!hideTooltip && <Info product={product} title={title} tooltip={tooltip} />}
          </div>
        )}
      </div>
      <div className="result-card-row">
        <div>{location}</div>
        <OptionalText
          text={
            isAccommodationTab
              ? item?.category
              : item?.duration && formatDuration(item.duration)
          }
          tag="div"
        />
      </div>
      <div className="result-card-tag-row">
        {isAccommodationTab && holidayCheck ?
          <OptionalText
            className="result-card-tag"
            tag="span"
            text={holidayCheck}
            format={(x) => `HolidayCheck: ${x}%`}
          /> : null}
        {
          tags?.map((tag, index) => (
            <span className="result-card-tag" key={index}>
              {tag}
            </span>
          ))
        }
        {contingentLabel ? <OptionalText
          className="result-card-tag"
          tag="span"
          text={contingentLabel}
          color={availableContingent > 0 ? '#4caf50' : (availableContingentNet < 0 ? '#dc3545' : '#ffca28')}
          format={(x) => `Kontingent: ${x}`}
        /> : null}
        {cancellationPeriod ? <OptionalText
          className="result-card-tag"
          tag="span"
          text={'Stornofrist: ' + cancellationPeriod + 'T'}
        /> : null}
      </div>
      <div className="result-card-row">
        <div>{priceLabel ? `${priceLabel} , p.P.` : "Preis per Person"}</div>
        <div>{formattedPrice ? `ab ${formattedPrice}` : 'Kein Preis'}</div>
      </div>
      {item?.fare && (
        <OptionalText tag="div" text={item?.fare} className="result-card-row" />
      )}
      {comparePriceFormatted && (
        <div className="result-card-row">
          <div>Vergleichspreis (hotelbeds.de)</div>
          <div>{`ab ${comparePriceFormatted}`}</div>
        </div>
      )}
      <OptionalText
        tag="div"
        style={{
          marginTop: "10px",
        }}
        text={item?.serviceProvider}
        className="result-card-row"
        format={(x) => `Lieferant: ${x}`}
      />
    </FlexboxGrid>
  );
};
