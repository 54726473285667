import {bindable, customElement, inject} from 'aurelia-framework';
import {ModelLabelService} from "../../../../reference/model-label-service";

@customElement('order-item-title-display')
@inject(ModelLabelService)
export class TitleDisplay {
    //Cart title display used for moja atm

    @bindable item;

    constructor(modelLabelService)
    {
        this.modelLabelService = modelLabelService;
    }

    async bind()
    {
        if (this.item.product) {
            this.link = await this.modelLabelService.getReferenceLinks(this.item.product, false);
            this.dummyFlight = this.item.product?.estimatedFlightTime;
            if (this.item.product.freeParticipants) {
                this.item.product.freeParticipants = this.item.product?.freeParticipants?.join("\r")?.replaceAll("&amp;","&");
            }
        }
    }

    _ownTitleElements() {

        return [
            'tourism-insurance/insurance',
            'tourism-insurance/api',
            'tourism/journey',
            'tourism-ferry/ferry',
            'tourism-accommodation/room',
            'tourism-service/service',
            'tourism-flight/itinerary',
            'tourism-travel-package/travel-package',
            'tourism-transfer/transfer',
            'tourism-aer-api/flight',
            'tourism-bus-routing/route-itinerary',
            'tourism-event/event',
            'voucher/voucher-redeem',
            'tourism-dbtov/rail-and-fly',
            'tourism-dbtov/cruise',
            'mair-dumont/order',
        ];
    }
}
