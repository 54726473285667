import {bindable} from 'aurelia-framework';
import moment from "moment";

export class ParticipantInformation {
    @bindable participant;
    @bindable cart;

    typeIdentifier;
    typeTitle;

    bind() {
        this.participantType();
    }

    participantType() {

        let birthdate = this.participant?.customer?.birthdate ?? null;

        this.typeIdentifier = null;
        this.typeTitle = null;

        if (birthdate === null) {
            return null;
        }

        let years = moment().diff(moment(birthdate), 'years');

        if (years < 2) {
            this.typeIdentifier = 'INF';
            this.typeTitle = 'Kleinkind';
        } else if (years < 18) {
            this.typeIdentifier = 'CH';
            this.typeTitle = 'Kind';
        } else {
            this.typeIdentifier = 'AD';
            this.typeTitle = 'Erwachsener';
        }
    }

}
