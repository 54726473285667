import {DialogController} from 'aurelia-dialog';
import {inject} from 'aurelia-framework';
import {Client} from '../../api/client';

@inject(DialogController, Client)
export class OrderTourismCopyDialog {

    dialogController;
    client;

    move;
    participants;
    result;

    constructor(dialogController, client) {
        this.dialogController = dialogController;
        this.client = client;
    }

    activate(context) {
        this.participants = context.participants;
        this.move = context.move || false;
        this.result = {
            mode: 'participant',
            selectedParticipants: context.selectedParticipants
        };
    }
}
